<template>
<!-- BEGIN HIGHLIGHT -->
<div class="content">
    <div class="container">
        <div class="row">
            <div class="main col-sm-12">
                <div class="center">
                    <br>
                    <h2 class="section-highlight text-black" data-animation-direction="from-left" data-animation-delay="50">SIC Bienes Raíces ofrece:</h2>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                                                 
                            <p class="text-black  text-justify text-uppercase" data-animation-direction="from-left" data-animation-delay="650">
                                         <ul>
                                           <li>Avalúos</li>
                                           <li>Intermediación en Compra – Venta y Renta de Inmuebles</li>
                                           <li>Asesoría Hipotecaria</li>
                                           <li>Asesoría legal inmobiliaria</li>
                                           <li>Orientación fiscal en materia inmobiliaria</li>
                                       </ul>                   
                            </p>         
                        </div>
                    </div> 
                    <br>
                    <div class="row">
                     
                   

                        <div class="col-md-4">
                            <h2 class="text-black " data-animation-direction="from-left" data-animation-delay="250">
                                <b><b>{{$t("pages.about.vision.title")}}</b> </b>
                            </h2><br>
                                <p class="text-black text-justify text-uppercase " data-animation-direction="from-left" data-animation-delay="650">
                                Ser la mejor alternativa para quienes requieren apoyo de un profesional inmobiliario con espíritu de servicio, altamente capacitado y comprometido.
                            </p>
                        </div>
                        <div class="col-md-4">
                            <h2 class="text-black " data-animation-direction="from-left" data-animation-delay="250">
                                <b>{{$t("pages.about.mision.title")}}</b> 
                            </h2><br>
                                <p class="text-black  text-justify text-uppercase" data-animation-direction="from-left" data-animation-delay="650">
                                Ofrecer nuestros conocimientos y experiencia al servicio de la comunidad mediante asesoría personalizada, orientada a cuidar el patrimonio de nuestros clientes. 
                            </p>
                        </div>
                        <div class="col-md-4">
                            <h2 class="text-black " data-animation-direction="from-left" data-animation-delay="250">
                                <b><b>{{$t("pages.about.values.title")}}</b> </b>
                            </h2><br>
                                <p class="text-black text-justify text-uppercase " data-animation-direction="from-left" data-animation-delay="650">
                                    <ul>
                                        <li>Servicio de Excelencia</li>
                                        <li>Integridad</li>
                                        <li>Confianza</li>
                                    </ul>
                            </p>
                        </div>
                    </div>
                    <br>
             
                </div>
                <div class="row">
                    <div class="col-sm-12 text-center mb-4">
                        <div class="dadFace">				
                            <ul class="social-networks" id="footer_social" style="float:none;"> 
                                <li><h4 class="text-black"><b>Búscanos en redes:</b></h4></li> <!-- || pinterest!='0' -->
                                <li><a  style="color:#3b5998 !important" data-toggle="tooltip" data-placement="top" title="sic360"  class="facebook" href="https://www.facebook.com/sic360/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li><a style="color:#3b5998 !important"  data-toggle="tooltip" data-placement="top" title="likasa.inmuebles"  class="facebook" href="https://www.facebook.com/likasa.inmuebles/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li><a style="color:#1da1f2 !important"  data-toggle="tooltip" data-placement="top" title="Blog"  class="twitter" href="https://sicbienesraices.com.mx/blog/"><i class="fa fa-globe"></i></a>
                                </li>
                            </ul>				
                        </div>
                    </div>
                            <br>
                    <div class="col-sm-12 text-center">
                        <router-link to="/contacto" class="btn btn-default-color animate-from-left animation-from-left"
                                        >{{$t("pages.about.contact-button")}}</router-link
                                >
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END HIGHLIGHT -->

</template>


<script>
import { mapGetters } from 'vuex';
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
export default {
  components:{
    LocaleSwitcher
  },
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
    },
}
</script>

<style scoped>
.logo{
    width: 40% !important;
}

.mt-5{
    margin-top: 40px;
}
</style>