<template>
<footer id="footer">
    <div id="footer-top" class="container">
        <div class="row">
            <div class="block col-sm-4 text-center img-center" v-if="info.logo">
                <a href="index.html" class="img-center">
                  <img v-if="info.logo"
                  class="footer-logo"
                  :src="info.logo"
                  alt="logo_web" />
                  <h5 v-else>{{ info.companyName }}</h5>
                </a>
            </div>
            <div class="block col-sm-4 pr-3">
                <h3>{{$t("layout.branding.contact_title")}}</h3>
                <ul class="footer-contacts">
                    <li><i class="fa fa-map-marker"></i> {{info.address}}</li>
                    <li>   <a
                    class="contact-links"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Contáctenos"
                    :href="'tel:+52' + info.phone"
                    target="_blank"
                  ><i class="fa fa-phone"></i> {{info.phone}} </a></li>
                    <li>
                            <a
                    class="contact-links"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Contáctenos"
                    :href="'mailto:' + info.reserveemail"
                    target="_blank"
                  >
                      <i class="fa fa-envelope"></i> {{info.reserveemail}}</a></li>
                </ul>
            </div>
            <div class="block col-sm-4 footer-p" >
                <h3>{{$t("layout.branding.menu_title")}}</h3>
                  <ul class="footer-links">
                    <li><router-link to="/">{{$t("layout.nav.home")}}</router-link></li>
                    <li><router-link to="/nosotros">{{$t("layout.nav.about.title")}}</router-link></li>
                    <li><router-link to="/propiedades">{{$t("layout.nav.properties")}}</router-link></li>
                    <!-- <li><router-link to="/desarrollos">{{$t("layout.nav.developments")}}</router-link></li> -->
                    <li><a href="https://sicbienesraices.com.mx/blog">Blog</a></li>
                    <li><router-link to="/contacto">{{$t("layout.nav.contact")}}</router-link></li>
                    <li><router-link to="/equipo">{{ $t("routes.agents.page_title")}}</router-link></li>
                    
                    <li><a href="https://immocrm360.com.mx/" target="_blank">IMMO CRM 360<sup>&copy;</sup></a></li>
                  </ul>
            </div >

          
        </div>
    </div>
    <!-- BEGIN COPYRIGHT -->
    <div id="copyright">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                   <!-- BEGIN SOCIAL NETWORKS -->
                    <ul class="social-networks" v-if="info.fb != '0' || info.tw != '0' || info.linkedin != '0' || info.pinterest != '0' || info.instagram != '0' || info.youtube != '0'">
                        <li><h3> {{ $t("pages.property_detail.share_prop") }} </h3></li>
                        <li><a class="facebook" :href="info.fb" target="_blank" ><i class="fab fa-facebook-f"></i></a></li>
                        <li><a class="twitter" :href="info.tw" target="_blank" ><i class="fab fa-twitter"></i></a></li>
                        <li><a class="linkedin" :href="info.linkedin" target="_blank" ><i class="fab fa-linkedin"></i></a></li>
                        <li><a class="pinterest" :href="info.pinterest"  target="_blank" ><i class="fab fa-pinterest"></i></a></li>
                        <li><a class="instagram" :href="info.instagram" target="_blank" ><i class="fab fa-instagram"></i></a></li>
                        <li><a class="youtube" :href="info.youtube" target="_blank" ><i class="fab fa-youtube"></i></a></li>

                    </ul>
                    <!-- END SOCIAL NETWORKS -->
                   {{ info.companyName }}<sup>&copy;</sup> {{ year }}. {{ $t("layout.branding.copyright") }} Powered with <i class="fa fa-heart"></i> & <i class="fa fa-coffee"></i> by <a href="https://www.immocrm360.com.mx/" target="_blank">IMMO CRM 360<sup>&copy;</sup></a>
                    
                   
                </div>
            </div>
        </div>
    </div>
    <!-- END COPYRIGHT -->
</footer>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      year: "",
    };
  },
  created() {
    let d = new Date();
    this.year = d.getFullYear();
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>

<style scoped>
/* .footer-links{
  text-transform: uppercase !important;
} */
.footer-logo {
  display: inline-block;
  max-width: 35% !important;
  margin-top: auto;
  margin-bottom: 45px;
}

.footer-logo img {
  margin-top: 40%;
  margin-left: -10px !important;
  max-width: 165px !important;
  max-height: 200px !important;
}

.img-center{
  top: 35px;
}

/* .footer-links{
  columns: 2;
}   */
.social-networks > li > a.facebook{
  color: #3b5998 !important;
}
</style>